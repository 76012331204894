<template>
  <div style="padding:16px">

    <!-- 头部部分 -->
    <div ref="search_d">
      <el-row type="flex" class="row-bg" :gutter="20">

        <el-col :span="6">
          <el-input placeholder="请输入商家名称/分类名称" v-model="queryInfo.condition.queryKey" class="input-with-select" clearable>
            <el-button slot="append" icon="el-icon-search" @click="getList"></el-button>
          </el-input>
        </el-col>
        <el-col>
          <el-button v-if="type==1" @click="newBtn" icon="el-icon-plus">新增</el-button>
        </el-col>
      </el-row>
    </div>

    <!-- 表格部分 -->
    <div>
      <el-table border ref="table" :data="tableData" tooltip-effect="dark" style="width: 100%" :height="height">
        <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>
          <el-table-column label="商家名称" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.merchantName }}</template>
          </el-table-column>
          <el-table-column label="分类名称" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.categoryName }}</template>
          </el-table-column>
          <el-table-column label="排序" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.seq }}</template>
          </el-table-column>
          <el-table-column label="创建日期" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.createDt }}</template>
          </el-table-column>
        <el-table-column label="操作" width="135" align="center">
          <template slot-scope="scope">
            <!-- <el-button type="text" @click="edit(scope.row)" size="small">编辑</el-button> -->
            <el-popconfirm title="确定删除吗？" @confirm="handleDelte(scope.row.id)">
              <el-button slot="reference" type="text">删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="block">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryInfo.currPage" :page-sizes="[100, 200, 300, 400]" :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>

    <el-dialog :title="(labelType=='add'?'新增':'编辑')" :visible.sync="showDialog" width="520px">
      <el-form ref="form" :model="form"  label-width="90px">
        <el-row >
            <el-col :span="20">
              <el-form-item label="分类名称：" prop="categoryName">
                <el-input v-model="form.categoryName" placeholder="请输入分类名称"></el-input>
              </el-form-item>
            </el-col>

        </el-row>
      </el-form>
      <span slot="footer">
        <el-button size="small" @click="showDialog = false">取消</el-button>
        <el-button size="small" type="primary" @click="handleSave">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      type:JSON.parse(localStorage.getItem('userInfo')).userInfo.type,
      labelType: 'add',
      rules: {},
      total: 0,
      queryInfo: {
        pageSize: 100,
        pageCount: 1,
        currPage: 1,
        condition: {
          merchantCode:JSON.parse(localStorage.getItem('userInfo')).userInfo.objectCode
        }
      },
      form: {
        categoryName:""
      },
      showDialog: false,
      tableData: [],
      height: window.innerHeight - 256, //表格高度
      headerHeight: 0
    };
  },

  created () {
    this.getList()
  },

  mounted () {
    this.$nextTick(() => {
      // 获取定义ref属性 和 元素高度
      this.headerHeight = this.$refs.search_d.offsetHeight;
      window.addEventListener('resize', this.getHeight())
    })
  },

  methods: {
    // 监听表格高度
    getHeight () {
      this.height = window.innerHeight - this.headerHeight - 256;
    },

    //获取数据
    getList () {
	  var that = this;
	  that.$http.post("/productCategory/list", that.queryInfo).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
          that.total = response.data.data.count;
          that.queryInfo.currPage=response.data.data.currPage
          that.tableData = response.data.data.data;
        }
      });
    },

    // 保存
    handleSave () {
	    var that = this;
      let data={
        ...this.form,
        merchantCode:JSON.parse(localStorage.getItem('userInfo')).userInfo.objectCode
      }
	  that.$http.post("productCategory/addCategory", data).then(function (response) {
		  if (response.data.code == 200) {
			  that.$notify.success({
				title: "提示",
				message: "保存成功",
				showClose: true,
			  });
			 that.showDialog = false
			  that.getList()
		  }else{
			  that.$notify.info({
				title: "提示",
				message: response.data.message,
				showClose: true,
			  });
		  }
      }).catch(function(error){


      })


    },

    //修改
    edit (row) {
      this.showDialog = true;
      this.labelType = 'edit'
      this.form = JSON.parse(JSON.stringify(row))
    },

    // 删除当前行
//    handleDelte (id) {
//      del({ id: id }).then(res => {
//        if (res.code == 200) {
 //         this.$message.success('删除成功')
 //         this.getList()
 //       }
//      })
//    },

	// 删除用户
    async handleDelte(id) {
      let res=await this.$http.post("/productCategory/delete" ,{id})
			if(res.data.code == 200){
			 this.$notify.success({
				title: "提示",
				message: "删除成功",
				showClose: true,
			  });
			  this.getList();
			}else{
        this.$message.error(res.data.message)


      }

    },

    //新增按钮
    newBtn () {
      this.labelType = 'add'
      this.showDialog = true;
      this.form = {
        categoryName:""
      }
    },

    // 修改页数大小
    handleSizeChange (val) {
      this.queryInfo.pageSize = val;
      this.getList()
    },

    // 获取当前页面
    handleCurrentChange (val) {
      this.queryInfo.currPage = val;
      this.getList()
    },

  },
};
</script>

<style scoped>
</style>

